// eslint-disable-next-line import/no-extraneous-dependencies
import { jwtDecode } from '@utils/jwt';
import posthog from 'posthog-js';
import { STORAGE_KEY } from '@context/auth/auth-provider';

const PH_CONFIG = {
  api_host: import.meta.env.VITE_REACT_APP_PH_API_HOST as string,
  person_profiles: 'identified_only'
};

const initPostHog = () => {
  const token = sessionStorage.getItem(STORAGE_KEY);
  const user = token ? jwtDecode(token) : null;

  const isLocalOrTestEnvironment =
    window.location.host.includes('127.0.0.1') ||
    window.location.host.includes('localhost') ||
    window.location.host.includes('dashboard.dev.mygrowdash.com');
  const isInvalidUser =
    user?.username?.endsWith('@mygrowdash.com') || user?.isCustomerSuccessUser;

  if (!isLocalOrTestEnvironment && !isInvalidUser) {
    posthog.init(
      import.meta.env.VITE_REACT_APP_PH_API_KEY as string,
      PH_CONFIG as any
    );
  }

  return posthog;
};

export const posthogInstance = initPostHog();
